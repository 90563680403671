import React from "react";
import { autobind } from "react-decoration";
import qs from "query-string";
import navigate from "lib/navigate";
import KeyboardInput from "components/form/KeyboardInput";
import SrAboutLightBox from "components/resource/SrAboutLightBox";
import client from "lib/ApolloClient";
import gql from "graphql-tag";

const getResourceRelatedField = gql`
  query getResourceRelatedField {
    getResourceRelatedField {
      journalFieldList
    }
  }
`;

class SrComp extends React.Component {
  constructor(props) {
    super(props);

    let { search } = props;
    let params = { ...search };
    let { searchField = "all", searchInput = "" } = params;

    this.state = {
      relatedField: null,
      searchField: searchField,
      searchInput: searchInput,
    };

    this.searchInput = React.createRef();
  }

  componentDidMount() {
    client.jumperrwdClient
      .query({
        query: getResourceRelatedField,
      })
      .then((res) => {
        if (res.data.getResourceRelatedField) {
          this.setState({ relatedField: res.data.getResourceRelatedField });
        }
      });
  }

  @autobind
  changeSR(sr, e) {
    e.preventDefault();
    let { location } = this.props;
    let params = {};
    params.sr = sr;
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  handleSearchInputChange() {
    let searchInput = this.searchInput.current.val();
    this.setState({ searchInput: searchInput });
  }

  @autobind
  onSubmit(e) {
    e.preventDefault();
    this.submitForm();
  }

  @autobind
  submitForm() {
    let { t, search, searchForm } = this.props;
    let { searchField, searchInput } = this.state;
    let paramsTemp = { ...search };
    let { resourceType } = searchForm;
    let params = {};
    
    if (searchInput === "") {
      alert(t("jumperrwd.common.pleaseEnterSearchword"));
      return;
    }

    params = {
      searchField,
      searchInput,
      resourceType: resourceType || "",
      sr: paramsTemp.sr,
    };

    navigate(["/srResourceList", qs.stringify(params)].join("?"));
  }

  @autobind
  openAboutLightBox(e) {
    e.preventDefault();
    this.srAboutLightBox.popup();
  }

  @autobind
  handleSearchFieldChange(e) {
    this.setState({ searchField: e.target.value });
  }

  render() {
    let { t, searchForm } = this.props;
    let { sr, resourceType } = searchForm;
    let { relatedField, searchField } = this.state;
    return (
      <>
        <div className="advanced_search">
          <div className="advanced_block">
            <form onSubmit={this.onSubmit}>
              <div className="col-12">
                <div className="col">
                  <div className="searchblockdata">
                    <h2>
                      適讀年級？ SR值{" "}
                      <a
                        href="javascript:;"
                        className="about_sr"
                        onClick={this.openAboutLightBox}>
                        SR值是什麼?
                      </a>
                    </h2>
                    <div className="form_grp">
                      <div className="form_content">
                        <div className="grade_list">
                          <ul>
                            <li className="firstgrade">
                              <a
                                href="javascript:;"
                                onClick={this.changeSR.bind(this, "1")}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.changeSR("1", e);
                                  }
                                }}>
                                <img alt="" src="images/link_book.png" />
                                {t("jumperrwd.sr.1")}
                              </a>
                            </li>
                            <li className="secondgrade">
                              <a
                                href="javascript:;"
                                onClick={this.changeSR.bind(this, "2")}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.changeSR("2", e);
                                  }
                                }}>
                                <img alt="" src="images/link_book.png" />
                                {t("jumperrwd.sr.2")}
                              </a>
                            </li>
                            <li className="thirdgrade">
                              <a
                                href="javascript:;"
                                onClick={this.changeSR.bind(this, "3")}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.changeSR("3", e);
                                  }
                                }}>
                                <img alt="" src="images/link_book.png" />
                                {t("jumperrwd.sr.3")}
                              </a>
                            </li>
                            <li className="fourthgrade">
                              <a
                                href="javascript:;"
                                onClick={this.changeSR.bind(this, "4")}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.changeSR("4", e);
                                  }
                                }}>
                                <img alt="" src="images/link_book.png" />
                                {t("jumperrwd.sr.4")}
                              </a>
                            </li>
                            <li className="fifthgrade">
                              <a
                                href="javascript:;"
                                onClick={this.changeSR.bind(this, "5")}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.changeSR("5", e);
                                  }
                                }}>
                                <img alt="" src="images/link_book.png" />
                                {t("jumperrwd.sr.5")}
                              </a>
                            </li>
                            <li className="sixthgrade">
                              <a
                                href="javascript:;"
                                onClick={this.changeSR.bind(this, "6")}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.changeSR("6", e);
                                  }
                                }}>
                                <img alt="" src="images/link_book.png" />
                                {t("jumperrwd.sr.6")}
                              </a>
                            </li>
                            <li className="seventhgrade">
                              <a
                                href="javascript:;"
                                onClick={this.changeSR.bind(this, "7")}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.changeSR("7", e);
                                  }
                                }}>
                                <img alt="" src="images/link_book.png" />
                                {t("jumperrwd.sr.7")}
                              </a>
                            </li>
                            <li className="eighthgrade">
                              <a
                                href="javascript:;"
                                onClick={this.changeSR.bind(this, "8")}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.changeSR("8", e);
                                  }
                                }}>
                                <img alt="" src="images/link_book.png" />
                                {t("jumperrwd.sr.8")}
                              </a>
                            </li>
                            <li className="ninthgrade">
                              <a
                                href="javascript:;"
                                onClick={this.changeSR.bind(this, "9")}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.changeSR("9", e);
                                  }
                                }}>
                                <img alt="" src="images/link_book.png" />
                                {t("jumperrwd.sr.9")}
                              </a>
                            </li>
                            <li className="tenthgrade">
                              <a
                                href="javascript:;"
                                onClick={this.changeSR.bind(this, "10")}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.changeSR("10", e);
                                  }
                                }}>
                                <img alt="" src="images/link_book.png" />
                                {t("jumperrwd.sr.10")}
                              </a>
                            </li>
                            <li className="eleventhgrade">
                              <a
                                href="javascript:;"
                                onClick={this.changeSR.bind(this, "11")}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.changeSR("11", e);
                                  }
                                }}>
                                <img alt="" src="images/link_book.png" />
                                {t("jumperrwd.sr.11")}
                              </a>
                            </li>
                            <li className="grade12">
                              <a
                                href="javascript:;"
                                onClick={this.changeSR.bind(this, "12")}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.changeSR("12", e);
                                  }
                                }}>
                                <img alt="" src="images/link_book.png" />
                                {t("jumperrwd.sr.12")}
                              </a>
                            </li>
                            <li className="grade12above">
                              <a
                                href="javascript:;"
                                onClick={this.changeSR.bind(this, "12u")}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.changeSR("12u", e);
                                  }
                                }}>
                                <img alt="" src="images/link_book.png" />
                                {t("jumperrwd.sr.12u")}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="searchblockdata">
                    <h2>查詢詞</h2>
                    <div className="form_grp">
                      <div className="form_content">
                        <div className="select_date_inline">
                          <div className="select_left">
                            <select
                              name="searchField"
                              title={t("jumperrwd.common.filterCondition")}
                              value={searchField}
                              onChange={this.handleSearchFieldChange.bind(
                                this
                              )}>
                              <option value="all">
                                {t("jumper.common.all")}
                              </option>
                              {relatedField &&
                                relatedField.journalFieldList &&
                                relatedField.journalFieldList.map(
                                  (journalField) => {
                                    let nameField = "journalName";
                                    if (resourceType === "ebook") {
                                      nameField = "ebookName";
                                    }
                                    return (
                                      <option value={journalField}>
                                        {t(
                                          "jumperrwd.eb_journal_extension." +
                                            nameField +
                                            "." +
                                            journalField
                                        )}
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                          </div>
                          <div className="form_search">
                            <KeyboardInput
                              ref={this.searchInput}
                              value={this.state.searchInput}
                              name="searchInput"
                              type="text"
                              title={t("jumperrwd.common.enterKeyword")}
                              placeholder={t("jumperrwd.common.enterKeyword")}
                              onChange={this.handleSearchInputChange}
                            />
                            <img
                              alt="Display virtual keyboard interface"
                              className="keyboardInputInitiator"
                              src="/file/images/search_keyboard.gif"
                              title="Display virtual keyboard interface"
                            />
                          </div>
                          <div className="btn_grp">
                            <input
                              type="submit"
                              value={t("jumperrwd.common.search")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <SrAboutLightBox
          {...this.props}
          lightboxClass="personal_lightbox"
          blockClass="personalblock"
          ref={(c) => {
            this.srAboutLightBox = c;
          }}
        />
      </>
    );
  }
}

export default SrComp;
