import React from "react";
import LightBox from "components/common/LightBox";
import { autobind } from "react-decoration";

@LightBox
class SrAboutLightBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFixed: "none",
      scrolldown_fix: "",
    };
  }

  render() {
    let { t } = this.props;
    return (
      <>
        <h2>SR值是什麼？</h2>
        <p>
          SR值為國立臺灣師範大學的SmartReading適性閱讀團隊,利用人工智慧技術分析書籍文本的難度值,書籍的SR值愈大,表示這本書的難度愈高,所需要的閱讀能力也愈高(更多詳細內容請點{" "}
          <a href="https://smartreading.net/v3/about-SR.aspx" target="_blank">
            SmartReading
          </a>
          )。請參考以下適讀年齡對照表：
        </p>
        <table className="table_list2">
          <thead>
            <tr>
              <th>適讀年級？</th>
              <th>SR值</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-title="適讀年級？">一年級</td>
              <td data-title="SR值">~284</td>
            </tr>
            <tr>
              <td data-title="適讀年級？">二年級</td>
              <td data-title="SR值">284~357</td>
            </tr>
            <tr>
              <td data-title="適讀年級？">三年級</td>
              <td data-title="SR值">345~404</td>
            </tr>
            <tr>
              <td data-title="適讀年級？">四年級</td>
              <td data-title="SR值">395~453</td>
            </tr>
            <tr>
              <td data-title="適讀年級？">五年級</td>
              <td data-title="SR值">449~508</td>
            </tr>
            <tr>
              <td data-title="適讀年級？">六年級</td>
              <td data-title="SR值">478~536</td>
            </tr>
            <tr>
              <td data-title="適讀年級？">七年級</td>
              <td data-title="SR值">496~566</td>
            </tr>
            <tr>
              <td data-title="適讀年級？">八年級</td>
              <td data-title="SR值">503~581</td>
            </tr>
            <tr>
              <td data-title="適讀年級？">九年級</td>
              <td data-title="SR值">535~622</td>
            </tr>
            <tr>
              <td data-title="適讀年級？">十年級</td>
              <td data-title="SR值">602~659</td>
            </tr>
            <tr>
              <td data-title="適讀年級？">十一年級</td>
              <td data-title="SR值">646~708</td>
            </tr>
            <tr>
              <td data-title="適讀年級？">十二年級</td>
              <td data-title="SR值">661~720</td>
            </tr>
            <tr>
              <td data-title="適讀年級？">高於十二年級</td>
              <td data-title="SR值">720~</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

export default SrAboutLightBox;
